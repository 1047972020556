<script>
// import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
// import PageHeader from "@/components/page-header";
// import store from "@/state/store";
// var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
  },
  data() {
    return {
      HrSalaryItems: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchHrSalaryItems: null,
      HrSalaryItemsSearchMode: false,
      searchModel: "",
      HrEmployeeSalaryItems: [],
      // state: state,
    };
  },
  methods: {
    addHrSalaryItems() {
      // console.log("**********####", this.state);
      var data = {
        title: "popups.addHrSalaryItems",
        inputs: [
          {
            model: "hr_salary_item_id",
            type: "select",
            options: (() => {
              return this.HrSalaryItems.map((data) => {
                return {
                  value: data.id,
                  label: data.name,
                };
              });
            })(),
            label: this.$t("hr_employees.salary_item"),
          },
          {
            model: "amount",
            type: "number",
            label: this.$t("hr_employees.value"),
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.hr_emp_id = this.$route.params.id;
              this.http.post("hr-emp-salary-items", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editHrSalaryItems(app) {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "hr_salary_item_id",
            type: "select",
            options: (() => {
              return this.HrSalaryItems.map((data) => {
                return {
                  value: data.id,
                  label: data.name,
                };
              });
            })(),
            label: this.$t("hr_employees.salary_item"),
          },
          {
            model: "amount",
            type: "text",
            value: app.amount,
            label: this.$t("hr_employees.value"),
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.hr_emp_id = this.$route.params.id;
              this.http.put("hr-emp-salary-items", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.HrSalaryItemsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("hr-emp-salary-items/by-emp-id/search", {
          search: this.searchModel,
          limit: this.limit,
          hr_emp_id: this.$route.params.id,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.HrEmployeeSalaryItems = res.data;
          console.log("######## files", this.HrEmployeeSalaryItems);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.HrSalaryItemsSearchMode = false;
      this.get(this.page);
    },

    getHrSalaryItems() {
      this.http.get("hr-salary-items").then((res) => {
        this.HrSalaryItems = res.data;
      });
    },
    deleteHrSalaryItems(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("hr-emp-salary-items", app.id).then(() => {

            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("hr-emp-salary-items/by-emp-id/paginate", {
          limit: this.limit,
          page: page,
          hr_emp_id: this.$route.params.id,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.HrEmployeeSalaryItems = res.data;
        });
    },
  },
  created() {
    this.get(1);
    this.getHrSalaryItems();
  },
};
</script>

<template>
  <!-- <Layout> -->
  <div class="row">
    <div class="col-6">
      <div class="search-box chat-search-box w-50">
        <div class="position-relative">
          <input
            @keyup="search()"
            type="text"
            class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            :placeholder="$t('popups.search')"
            v-model="searchModel"
            style="background-color: #2a3042 !important"
          />
          <i class="bx bx-search-alt search-icon text-light"></i>
        </div>
      </div>
    </div>
    <div class="col-6" style="display: flex; justify-content: flex-end">
      <button
        class="btn-close text-light"
        v-if="HrSalaryItemsSearchMode"
        @click="cancelappsearchMode()"
      ></button>
      <button
        type="button"
        @click="addHrSalaryItems()"
        class="btn btn-light float-end mb-4"
      >
        <span
          class="bx bxs-plus-circle float-end fa-2x"
          style="color: #2a3042 !important"
        ></span>
      </button>
    </div>
  </div>

  <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
    <thead>
      <tr
        class="text-light text-center"
        style="background-color: #2a3042 !important"
      >
        <th scope="col">{{ $t("hr_employees.id") }}</th>
        <th scope="col">{{ $t("hr_employees.employee") }}</th>
        <th scope="col">{{ $t("hr_employees.salary_item") }}</th>
        <th scope="col">{{ $t("hr_employees.value") }}</th>
        <th scope="col">{{ $t("hr_employees.created") }}</th>
        <th scope="col">{{ $t("hr_employees.updated") }}</th>
        <th scope="col">{{ $t("hr_employees.operations") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(app, index) in HrEmployeeSalaryItems"
        :key="app"
        class="text-center"
      >
        <td>{{ (index += 1) }}</td>
        <td>{{ app?.hr_emp?.name }}</td>
        <td>{{ app?.hr_salary_item?.name }}</td>
        <td>{{ app?.amount }}</td>
        <td>{{ app?.created?.split("T")[0] }}</td>
        <td>{{ app?.updated?.split("T")[0] }}</td>

        <td class="d-flex">
          <a
            class="btn btn-primary mx-1"
            @click="editHrSalaryItems(app)"
            href="javascript: void(0);"
            role="button"
            >{{ $t("popups.edit") }}</a
          >
          <a
            class="btn btn-danger"
            href="javascript: void(0);"
            role="button"
            @click="deleteHrSalaryItems(app)"
            >{{ $t("popups.delete") }}</a
          >
        </td>
      </tr>
    </tbody>
  </table>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <div v-if="tot_pages > 1">
    <!--   Apps  paginate     -->
    <ul class="paginate paginate-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->
  <!-- </Layout> -->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
