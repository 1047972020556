<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import HrEmployeeSalaryItems from "./HrSalaryItems/HrEmployeeSalaryItems.vue";
import MonthlyDeductionDetailsPage from "./MonthlyDeductionDetails/MonthlyDeductionDetailsPage.vue";
import HrEmployeeAwards from "./HrAwards/HrEmployeeAwards.vue";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    HrEmployeeSalaryItems,
    MonthlyDeductionDetailsPage,
    HrEmployeeAwards,
  },
  data() {
    return {
      // Start Global Data
      // state: state,
      employee: {},
      profileTitle: "",
      // End Global Data
      // start jhr employee informa
      hrEmployeeDepartmenttons: {},
      informations: [],
      // hr_job_title: {},
      hr_department: [],
      hr_weekdays: [],
      // end jhr employee informatons

      // start hr employee working hours setup
      HrEmployeesWorkingHours: [],
      weekdays: [
        { id: 1, name: "Saturday" },
        { id: 2, name: "Sunday" },
        { id: 3, name: "Monday" },
        { id: 4, name: "Tuesday" },
        { id: 5, name: "Wednesday" },
        { id: 6, name: "Thursday" },
        { id: 7, name: "Friday" },
      ],
      HrEmployeesWorkingHoursSearchMode: false,
      WorkingHoursPage: 1,
      employeeWrokingHoursSearchModel: "",
      // start hr employee working hours setup

      // start employee hr leaves
      HrLeaves: [],
      HrLeavesTypes: [],
      leaglConditions: [
        { name: "Yes", value: 1 },
        { name: "No", value: 0 },
      ],
      isLegal: false,
      leaveType: {
        date: null,
        descr: null,
      },
      selectedLeaveTypeEmployee: {},
      leavesLimit: 20,
      leavesPage: 1,
      leavesTotalPages: 0,
      leaveSearchMode: false,
      leaveSearchModel: "",
      // end employee hr leaves
      // start employee hr files
      HrEmployeeFiles: [],
      employeeFile: {},
      filesPage: 1,
      filesLimit: 20,
      filesTotalPages: 0,
      searchHrEmployeeFiles: null,
      HrEmployeeFilesSearchMode: false,
      filesSearchModel: "",
      // end employee hr files
      users: [],
      Users: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchUsers: null,
      SearchMode: false,
      emps: [],
      new_roles: [],
      newRoles: [{}],
      addRoles: [{}],
      branches: [],
      user_info: {},
      new_pass: "",
      users_switch: "",
      user_status: 0,
      data: {},
      roles: [],

      // appprices:[]
    };
  },
  methods: {
    handleDateClick: function (arg) {
      alert("date click! " + arg.dateStr);
    },
    // Start employee information setup
    updateEmployeeData() {
      console.log(this.employee);
      const { id, ...updatedData } = this.employee;
      this.http.put("hr-employees", id, updatedData ).then(() => {
        this.getEmployeeData();
      });
    },
    // End employee information setup
    // start employee working hours setup
    addHrEmployeesWorkingHours() {
      var data = {
        title: "popups.add",
        inputs: [
          {
            model: "hr_weekday_id",
            type: "select",
            options: (() => {
              return this.hr_weekdays.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: this.$t('hr_employees.employee_profile.weekday'),
          },
          { model: "start_time", type: "time", label: this.$t('hr_employees.employee_profile.start_time') },
          { model: "end_time", type: "time", label: this.$t('hr_employees.employee_profile.end_time') },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.hr_employee_id = parseInt(this.$route.params.id);
              this.http.post("hr-employees-working-hours", obj).then(() => {
                this.getHrEmployeesWorkingHours(this.page, false);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editHrEmployeesWorkingHours(app) {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "hr_weekday_id",
            type: "select",
            options: (() => {
              return this.hr_weekdays.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "Week Day",
          },
          {
            model: "start_time",
            type: "time",
            label: "start_time",
            value: app.start_time,
          },
          {
            model: "end_time",
            type: "time",
            label: "end_time",
            value: app.end_time,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.hr_employee_id = app.hr_employee_id;
              this.http
                .put("hr-employees-working-hours", app.id, obj)
                .then(() => {
                  this.getHrEmployeesWorkingHours(this.page);
                });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    deleteHrEmployeesWorkingHours(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("hr-employees-working-hours", app.id).then(() => {
            this.getHrEmployeesWorkingHours(this.page, false);
          });
        }
      });
    },
    getHrEmployeesWorkingHours(page, tabClick) {
      if (this.HrEmployeesWorkingHours.length > 0 && tabClick) return;
      this.http
        .post("hr-employees-working-hours/hr-employee-working-hours", {
          employee_id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.WorkingHoursPage = page;
          this.HrEmployeesWorkingHours = res.data;
          this.HrEmployeesWorkingHours.forEach((day) => {
            this.weekdays.forEach((week) => {
              if (week.id == day.weekday) {
                day.day = week.name;
              }
            });
          });
        });
    },
    // end employee working hours setup
    // start employee leaves setup
    getHrLeaves(page, tabClick) {
      if (this.HrLeaves.length > 0 && tabClick) return;
      this.http
        .post("hr-leaves/employee/pagination", {
          limit: this.leavesLimit,
          page: this.leavesPage,
          id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.leavesPage = page;
          this.leavesTotPages = Math.ceil(res.tot / this.limit);
          this.HrLeaves = res.data;
        });
    },
    deleteHrLeaves(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http
            .post("hr-leaves/delete-leave-file", {
              id: app.id,
              file: app?.file,
            })
            .then(() => {
              this.getHrLeaves(this.leavesPage, false);
            });
        }
      });
    },
    getLeaves(app) {
      if (app) {
        this.selectedLeaveTypeEmployee = app;
      }
      if (this.HrLeavesTypes.length <= 0) {
        this.http.post("hr-leave-types/get-types", {}).then((resp) => {
          if (resp.status) {
            this.HrLeavesTypes = resp.data;
            this.HrLeavesTypes.push({ name: "other", id: null });
          }
        });
        return;
      }
    },
    addHrLeaves() {
      console.log(this.leaveType);
      this.leaveType.hr_employee_id = parseInt(this.$route.params.id);
      if (!this.leaveType.hr_leave_type_id) {
        delete this.leaveType.hr_leave_type_id;
      }
      if (this.leaveType?.file) {
        this.http.do("hr-leaves", this.leaveType).then(() => {
          this.getHrLeaves(this.leavesPage, false);
          this.leaveType = { date: null, descr: null };
        });
        return;
      }
      this.http.post("hr-leaves", this.leaveType).then(() => {
        this.getHrLeaves(this.leavesPage, false);
        this.leaveType = { date: null, descr: null };
      });
    },
    editHrLeaves(){
      console.log('aaa',this.selectedLeaveTypeEmployee);
      this.selectedLeaveTypeEmployee.hr_employee_id = parseInt(this.$route.params.id);
      this.http.put("hr-leaves",this.selectedLeaveTypeEmployee.id, this.selectedLeaveTypeEmployee).then(() => {
        this.getHrLeaves(this.page);
      });
    },
    // editHrLeaves(app) {
    //   var data = {
    //     title: "popups.edit",
    //     inputs: [
    //       {
    //         model: "hr_employee_id",
    //         type: "text",
    //         label: "hr_employee_id",
    //         value: app.hr_employee_id,
    //       },
    //       { model: "date", type: "text", label: "date", value: app.date },
    //       {
    //         model: "hr_leave_type_id",
    //         type: "text",
    //         label: "hr_leave_type_id",
    //         value: app.hr_leave_type_id,
    //       },
    //       {
    //         model: "is_legal",
    //         type: "text",
    //         label: "is_legal",
    //         value: app.is_legal,
    //       },
    //       { model: "descr", type: "text", label: "descr", value: app.descr },
    //       { model: "file", type: "file", label: "file", value: app.file },
    //     ],
    //     buttons: [
    //       {
    //         text: "popups.edit",
    //         closer: true,
    //         color: "primary",
    //         handler: (obj) => {
    //           obj.hr_employee_id = parseInt(this.$route.params.id);
    //           this.http.do("hr-leaves-update", obj).then(() => {
    //             this.get(this.page);
    //           });
    //         },
    //       },
    //       {
    //         text: "popups.cancel",
    //         closer: true,
    //         color: "danger",
    //         handler: () => {
    //           console.log("Like Clicked");
    //         },
    //       },
    //     ],
    //   };
    //   this.popup.modal(data);
    // },
    showIsLeagal() {
      if (this.leaveType.hr_leave_type_id) {
        this.isLegal = false;
        return;
      }
      this.isLegal = true;
      console.log({
        type: this.leaveType,
        types: this.HrLeavesTypes,
        leagal: this.isLegal,
      });
    },
    leaveSearch() {
      this.leaveSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("hr-leaves/leave-search", {
          search: this.leaveSearchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.HrLeaves = res.data;
          console.log("######## files", this.HrLeavesTypes);
        });
    },
    cancelLeaveSearchMode() {
      this.leaveSearchModel = "";
      this.leaveSearchMode = false;
      this.get(this.page);
    },
    // end employee leaves setup

    // start employee files setup

    addHrEmployeeFiles() {
      this.employeeFile.hr_employee_id = parseInt(this.$route.params.id);
      this.http.do("hr-employee-files", this.employeeFile).then(() => {
        this.getHrEmployeeFiles(this.filesPage, false);
        this.employeeFile = {};
      });
    },
    editHrEmployeeFiles(app) {
      var data = {
        title: "popups.edit",
        inputs: [
          // {
          //   model: "hr_employee_id",
          //   type: "text",
          //   label: "hr_employee_id",
          //   value: app.hr_employee_id,
          // },
          { model: "name", type: "text", label: this.$t("hr_employee_files.name"), value: app.name },
          { model: "file", type: "text", label: this.$t("hr_employee_files.file"), value: app.file },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("hr-employee-files", app.id, obj).then(() => {
                this.getHrEmployeeFiles(this.page)
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.HrEmployeeFilesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("hr-employee-files/search", {
          search: this.filesSearchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.HrEmployeeFiles = res.data;
          console.log("######## files", this.HrEmployeeFiles);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.HrEmployeeFilesSearchMode = false;
      this.get(this.page);
    },

    getHrEmployeeFiles(page, tabClick) {
      if (this.HrEmployeeFiles.length > 0 && tabClick) return;
      this.http
        .post("hr-employee-files/employee/pagination", {
          limit: this.filesLimit,
          page: this.filesPage,
          id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.HrEmployeeFiles = res.data;
        });
    },
    deleteHrEmployeeFiles(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("hr-employee-files", app.id).then(() => {
            this.getHrEmployeeFiles(this.filesPage, false);
          });
        }
      });
    },

    // end employee files setup
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], true);
    },
    onEmployeeFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], false);
    },
    createImage(file, isLeave) {
      isLeave ? (this.leaveType.file = file) : (this.employeeFile.file = file);
    },
    getEmployeeData() {
      this.http
        .post("hr-employees/select-employee-data", {
          id: parseInt(this.$route.params.id),
        })
        .then((resp) => {
          this.employee = resp.data;
          // this.hr_job_title = resp.data?.hr_job_title;
          this.hr_department = resp.data?.hr_department;
          // resp.data?.employeeDepartments.forEach((department) => {
          //   this.hr_department.push(department?.hr_department);
          // });
          this.profileTitle = this.employee?.name;
          this.informations = resp.data;
          // this.informations[0] = {
          //   employee: resp.data?.hr_employee,
          // };
          console.log('aaa', this.informations);
          console.log('aaa', this.informations.birthdate);
        });
    },
    getHrWeekDays() {
      this.http.get("hr-weekdays").then((res) => {
        this.hr_weekdays = res.data;
      });
    },
  },
  created() {
    if (this.$route.params.name) {
      this.employee = this.$route.params.name;
      return;
    }
    this.getEmployeeData();
    this.getHrWeekDays();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="`${profileTitle} ` + $t('hr_employees.profile')"
    />
    <!-- start tabs block -->

    <b-tabs
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
    >
      <b-tab active>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("hr_employees.employee_profile.informations") }}
          </span>
        </template>

        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr
              class="d-flex text-light text-start"
              style="background-color: #2a3042 !important"
            >
              <th scope="col" style="width: 400px">
                {{ $t("hr_employees.data") }}
              </th>
              <th scope="col" style="width: 200px">
                {{ $t("hr_employees.values") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center">
              <td>
                <tr class="d-flex">
                  <td class="p-1 overflow-auto text-start" style="width: 400px">
                    {{ $t("hr_employees.name") }}
                  </td>
                  <td>
                    <input
                      @change="updateEmployeeData()"
                      type="text"
                      style="width: 200px"
                      v-model="employee.name"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr class="d-flex">
                  <td class="p-1 overflow-auto text-start" style="width: 400px">
                    {{ $t("hr_employees.birthdate") }}
                  </td>
                  <td>
                    <input
                      @change="updateEmployeeData()"
                      type="date"
                      style="width: 200px"
                      v-model="employee.birthdate"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr class="d-flex">
                  <td class="p-1 overflow-auto text-start" style="width: 400px">
                    {{ $t("hr_employees.basic_salary") }}
                  </td>
                  <td>
                    <input
                      @change="updateEmployeeData()"
                      type="number"
                      style="width: 200px"
                      v-model="employee.basic_salary"
                      class="form-control"
                    />
                  </td>
                </tr>
                <!-- <tr class="d-flex">
                  <td class="p-1 overflow-auto text-start" style="width: 400px">
                    Job Title
                  </td>
                  <td>
                    <input
                      @change="updateEmployeeData()"
                      type="text"
                      style="width: 200px"
                      v-model="hr_job_title.name"
                      class="form-control"
                    />
                  </td>
                </tr> -->
                <tr class="d-flex">
                  <td class="p-1 overflow-auto text-start" style="width: 400px">
                    {{ $t("hr_employees.department") }}
                  </td>
                  <td>
                    <div>
                      <input
                        @change="updateEmployeeData()"
                        type="text"
                        style="width: 200px"
                        v-model="hr_department.name"
                        class="form-control"
                      />
                    </div>
                  </td>
                </tr>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- end  table -->
        <!-- Button trigger modal -->

        <div v-if="tot_pages >= 20">
          <!--   Apps  Pagination     -->
          <ul class="pagination pagination-rounded justify-content-center mb-2">
            <li class="page-item" :class="{ disabled: page == 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>
            <li
              class="page-item"
              :class="{ active: p == page }"
              v-for="p in tot_pages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <li class="page-item" :class="{ disabled: page == tot_pages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
        <!--end-->
      </b-tab>
      <!-- start tab 2 -->
      <b-tab @click="getHrEmployeesWorkingHours(1, true)">
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("hr_employees.employee_profile.shift_setup") }}
          </span>
        </template>
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6" style="display: flex; justify-content: flex-end">
            <button
              type="button"
              @click="addHrEmployeesWorkingHours()"
              class="btn btn-light float-end mb-4"
            >
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>
          </div>
        </div>

        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("hr_employees.id") }}</th>
              <th scope="col">
                {{ $t("hr_employees.employee_profile.weekday") }}
              </th>
              <th scope="col">
                {{ $t("hr_employees.employee_profile.start_time") }}
              </th>
              <th scope="col">
                {{ $t("hr_employees.employee_profile.end_time") }}
              </th>
              <th scope="col">{{ $t("hr_employees.created") }}</th>
              <th scope="col">{{ $t("hr_employees.updated") }}</th>
              <th scope="col">{{ $t("hr_employees.operations") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="app in HrEmployeesWorkingHours"
              :key="app"
              class="text-center"
            >
              <td>{{ app?.id }}</td>
              <td>{{ app?.hr_weekday?.name }}</td>
              <td>{{ app?.start_time }}</td>
              <td>{{ app?.end_time }}</td>
              <td>{{ app?.created.split("T")[0] }}</td>
              <td>{{ app?.updated.split("T")[0] }}</td>

              <td class="d-flex">
                <a
                  class="btn btn-primary mx-1"
                  @click="editHrEmployeesWorkingHours(app)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.edit") }}</a
                >
                <a
                  class="btn btn-danger"
                  href="javascript: void(0);"
                  role="button"
                  @click="deleteHrEmployeesWorkingHours(app)"
                  >{{ $t("popups.delete") }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <!-- end  table -->
        <!-- Button trigger modal -->

        <div v-if="tot_pages >= 20">
          <!--   Apps  Pagination     -->
          <ul class="pagination pagination-rounded justify-content-center mb-2">
            <li class="page-item" :class="{ disabled: page == 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>
            <li
              class="page-item"
              :class="{ active: p == page }"
              v-for="p in tot_pages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <li class="page-item" :class="{ disabled: page == tot_pages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
        <!--end-->
      </b-tab>
      <!-- end tab 2 -->
      <!-- start tab 3 -->
      <!-- <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("hr_employees.employee_profile.attendance") }}
          </span>
        </template>
        <h3>attendance</h3>
      </b-tab> -->
      <!-- end tab 3 -->
      <!-- start tab 4 -->
      <b-tab @click="getHrLeaves(1, true)">
        <template v-slot:title>
          <!-- <a role="button" @click="getHrLeaves(leavesPage, true)"> -->
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("hr_employees.employee_profile.leaves") }}
          </span>
          <!-- </a> -->
        </template>

        <div class="row">
          <div class="col-6">
            <div class="search-box chat-search-box w-50">
              <div class="position-relative">
                <input
                  @keyup="leaveSearch()"
                  type="text"
                  class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                  :placeholder="$t('popups.search')"
                  v-model="leaveSearchModel"
                  style="background-color: #2a3042 !important"
                />
                <i class="bx bx-search-alt search-icon text-light"></i>
              </div>
            </div>
          </div>
          <div class="col-6" style="display: flex; justify-content: flex-end">
            <button
              class="btn-close text-light"
              v-if="leaveSearchMode"
              @click="cancelLeaveSearchMode()"
            ></button>
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#addLeave"
              @click="getLeaves(false)"
              class="btn btn-light float-end mb-4"
            >
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>
          </div>
        </div>

        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("hr_leaves.id") }}</th>
              <th scope="col">{{ $t("hr_leaves.date") }}</th>
              <th scope="col">{{ $t("hr_leaves.hr_leave_type") }}</th>
              <th scope="col">{{ $t("hr_leaves.is_legal") }}</th>
              <th scope="col">{{ $t("hr_leaves.descr") }}</th>
              <th scope="col">{{ $t("hr_leaves.file") }}</th>
              <th scope="col">{{ $t("hr_leaves.created") }}</th>
              <th scope="col">{{ $t("hr_leaves.updated") }}</th>
              <th scope="col">{{ $t("hr_employees.operations") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="app in HrLeaves" :key="app" class="text-center">
              <td>{{ app?.id }}</td>
              <td>{{ app?.date }}</td>
              <td>
                {{
                  app?.hr_leave_type?.name ? app?.hr_leave_type?.name : "other"
                }}
              </td>
              <td v-if="app?.is_legal">{{ app?.is_legal ? "Yes" : "No" }}</td>
              <td v-if="!app?.is_legal">
                {{ app?.hr_leave_type?.is_legal ? "Yes" : "No" }}
              </td>
              <td>{{ app?.descr }}</td>
              <td>
                <a
                  v-if="app?.file"
                  :href="`${$store.state.filesUrl}${
                    app.file.split('./uploads/')[1]
                  }`"
                  >download file</a
                >
                <span v-else> - </span>
              </td>
              <td>{{ app.created.split("T")[0] }}</td>
              <td>{{ app.updated.split("T")[0] }}</td>

              <td class="d-flex">
                <button
                  class="btn btn-primary mx-1"
                  href="javascript: void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#editLeave"
                  @click="getLeaves(app)"
                >
                  {{ $t("popups.edit") }}
                </button>
                <a
                  class="btn btn-danger"
                  href="javascript: void(0);"
                  role="button"
                  @click="deleteHrLeaves(app)"
                  >{{ $t("popups.delete") }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <!-- end  table -->

        <!--Start Add leave modal-->
        <div
          class="modal fade"
          id="addLeave"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <div class="container px-0">
                  <form @submit.prevent="addHrLeaves()">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        {{ $t("popups.add") }}
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        style="margin: 0"
                      ></button>
                    </div>

                    <div class="mb-3">
                      <label class="form-label">
                        {{ $t("popups.adddate") }}
                      </label>
                      <input
                        type="date"
                        v-model="leaveType.date"
                        class="form-control"
                      />
                    </div>

                    <div class="mb-3 mt-4">
                      <label for="exampleInputEmail1" class="form-label">
                        {{ $t("hr_leaves.hr_leave_type") }}
                      </label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        v-model="leaveType.hr_leave_type_id"
                        @change="showIsLeagal"
                      >
                        <option
                          v-for="leave in HrLeavesTypes"
                          :key="leave"
                          :value="leave.id"
                        >
                          {{ leave.name }}
                        </option>
                      </select>
                    </div>

                    <div class="mb-3 mt-4" v-if="isLegal">
                      <label for="exampleInputEmail1" class="form-label">
                        {{ $t("hr_leaves.is_legal") }}
                      </label>
                      <select
                        required
                        class="form-select"
                        aria-label="Default select example"
                        v-model="leaveType.is_legal"
                      >
                        <option
                          v-for="condition in leaglConditions"
                          :key="condition"
                          :value="condition.value"
                        >
                          {{ condition.name }}
                        </option>
                      </select>
                    </div>

                    <label class="form-label"> {{ $t("hr_leaves.descr") }} </label>
                    <textarea
                      v-model="leaveType.descr"
                      id="descr"
                      class="form-control"
                      cols="30"
                      rows="10"
                    ></textarea>
                    <div class="mb-3 mt-4">
                      <label class="form-label">{{ $t("hr_leaves.file") }}</label>
                      <input
                        name="file"
                        @change="onFileChange($event)"
                        type="file"
                        class="form-control"
                      />
                    </div>
                    <div
                      class="modal-footer"
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    >
                      <button
                        type="submit"
                        class="btn btn-primary"
                        data-bs-dismiss="modal"
                      >
                        {{ $t("popups.add") }}
                      </button>
                      <button
                        data-bs-dismiss="modal"
                        type="button"
                        class="btn btn-danger"
                      >
                        {{ $t("popups.cancel") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--End leave modal -->

        <!--Start Edit leave modal-->
        <div
          class="modal fade"
          id="editLeave"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <div class="container px-0">
                  <form @submit.prevent="editHrLeaves()">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        {{ $t("popups.edit") }}
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        style="margin: 0"
                      ></button>
                    </div>

                    <div class="mb-3">
                      <label class="form-label">
                        {{ $t("popups.adddate") }}
                      </label>
                      <input
                        type="date"
                        v-model="selectedLeaveTypeEmployee.date"
                        class="form-control"
                      />
                    </div>

                    <div class="mb-3 mt-4">
                      <label for="exampleInputEmail1" class="form-label">
                        {{ $t("hr_leaves.hr_leave_type") }}
                      </label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        v-model="selectedLeaveTypeEmployee.hr_leave_type_id"
                        @change="showIsLeagal"
                      >
                        <option
                          v-for="leave in HrLeavesTypes"
                          :key="leave"
                          :value="leave.id"
                        >
                          {{ leave.name }}
                        </option>
                      </select>
                    </div>

                    <div
                      class="mb-3 mt-4"
                      v-if="selectedLeaveTypeEmployee.is_legal"
                    >
                      <label for="exampleInputEmail1" class="form-label">
                        {{ $t("hr_leaves.is_legal") }}
                      </label>
                      <select
                        required
                        class="form-select"
                        aria-label="Default select example"
                        v-model="selectedLeaveTypeEmployee.is_legal"
                      >
                        <option
                          v-for="condition in leaglConditions"
                          :key="condition"
                          :value="condition.value"
                        >
                          {{ condition.name }}
                        </option>
                      </select>
                    </div>

                    <label class="form-label"> {{ $t("hr_leaves.descr") }} </label>
                    <textarea
                      v-model="selectedLeaveTypeEmployee.descr"
                      id="descr"
                      class="form-control"
                      cols="30"
                      rows="10"
                    ></textarea>
                    <div class="mb-3 mt-4">
                      <label class="form-label"
                        >{{ $t("hr_leaves.file") }} :
                        <span class="text-gray">
                          {{
                            selectedLeaveTypeEmployee?.file
                              ? selectedLeaveTypeEmployee?.file.split("/")[2]
                              : ""
                          }}
                        </span>
                      </label>
                      <input
                        name="file"
                        @change="onFileChange($event)"
                        type="file"
                        class="form-control"
                      />
                    </div>
                    <div
                      class="modal-footer"
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    >
                      <button
                        type="submit"
                        class="btn btn-primary"
                        data-bs-dismiss="modal"
                      >
                        {{ $t("popups.add") }}
                      </button>
                      <button
                        data-bs-dismiss="modal"
                        type="button"
                        class="btn btn-danger"
                      >
                        {{ $t("popups.cancel") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--End Edit leave modal -->

        <div v-if="leavesTotalPages >= 20">
          <!--   Apps  Pagination     -->
          <ul class="pagination pagination-rounded justify-content-center mb-2">
            <li class="page-item" :class="{ disabled: page == 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>
            <li
              class="page-item"
              :class="{ active: p == page }"
              v-for="p in leavesTotalPages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <li
              class="page-item"
              :class="{ disabled: page == leavesTotalPages }"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
        <!--end-->
      </b-tab>
      <!-- end tab 4 -->
      <!-- start tab 5 -->
      <b-tab @click="getHrEmployeeFiles(1, true)">
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("hr_employees.employee_profile.files") }}
          </span>
        </template>
        <div class="row">
          <div class="col-6">
            <div class="search-box chat-search-box w-50">
              <div class="position-relative">
                <input
                  @keyup="search()"
                  type="text"
                  class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                  :placeholder="$t('popups.search')"
                  v-model="filesSearchModel"
                  style="background-color: #2a3042 !important"
                />
                <i class="bx bx-search-alt search-icon text-light"></i>
              </div>
            </div>
          </div>
          <div class="col-6" style="display: flex; justify-content: flex-end">
            <button
              class="btn-close text-light"
              v-if="HrEmployeeFilesSearchMode"
              @click="cancelappsearchMode()"
            ></button>
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#addEmployeeFile"
              class="btn btn-light float-end mb-4"
            >
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>
          </div>
        </div>

        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("hr_employee_files.id") }}</th>
              <th scope="col">{{ $t("hr_employee_files.name") }}</th>
              <th scope="col">{{ $t("hr_employee_files.file") }}</th>
              <th scope="col">{{ $t("hr_employee_files.created") }}</th>
              <th scope="col">{{ $t("hr_employee_files.updated") }}</th>
              <th scope="col">{{ $t("hr_employee_files.operations") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="app in HrEmployeeFiles" :key="app" class="text-center">
              <td>{{ app?.id }}</td>
              <td>{{ app?.name }}</td>
              <td>
                <a
                  v-if="app?.file"
                  :href="`${$store.state.filesUrl}${
                    app.file.split('./uploads/')[1]
                  }`"
                  >download file</a
                >
                <span v-else> - </span>
              </td>
              <td>{{ app.created.split("T")[0] }}</td>
              <td>{{ app.updated.split("T")[0] }}</td>

              <td class="d-flex">
                <a
                  class="btn btn-primary mx-1"
                  @click="editHrEmployeeFiles(app)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.edit") }}</a
                >
                <a
                  class="btn btn-danger"
                  href="javascript: void(0);"
                  role="button"
                  @click="deleteHrEmployeeFiles(app)"
                  >{{ $t("popups.delete") }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <!-- end  table -->

        <!--Start Add Employee file modal-->
        <div
          class="modal fade"
          id="addEmployeeFile"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <div class="container px-0">
                  <form @submit.prevent="addHrEmployeeFiles()">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        {{ $t("popups.add") }}
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        style="margin: 0"
                      ></button>
                    </div>

                    <label class="form-label"> {{ $t("hr_employee_files.name") }} </label>
                    <input
                      type="text"
                      id="employeeFile"
                      class="form-control"
                      required
                      v-model="employeeFile.name"
                    />
                    <div class="mb-3 mt-4">
                      <label class="form-label">{{ $t("hr_employee_files.file") }}</label>
                      <input
                        required
                        name="file"
                        @change="onEmployeeFileChange($event)"
                        type="file"
                        class="form-control"
                      />
                    </div>
                    <div
                      class="modal-footer"
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    >
                      <button
                        type="submit"
                        class="btn btn-primary"
                        data-bs-dismiss="modal"
                      >
                        {{ $t("popups.add") }}
                      </button>
                      <button
                        data-bs-dismiss="modal"
                        type="button"
                        class="btn btn-danger"
                      >
                        {{ $t("popups.cancel") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--End add employee file modal -->

        <!-- Button trigger modal -->

        <div v-if="tot_pages == 20">
          <!--   Apps  Pagination     -->
          <ul class="pagination pagination-rounded justify-content-center mb-2">
            <li class="page-item" :class="{ disabled: page == 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>
            <li
              class="page-item"
              :class="{ active: p == page }"
              v-for="p in tot_pages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <li class="page-item" :class="{ disabled: page == total_pages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
        <!--end-->
      </b-tab>
      <!-- end tab 5 -->

      <!-- start tab 6 -->
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("hr_employees.employee_profile.salary_items") }}
          </span>
        </template>
        <hr-employee-salary-items />
        <!--end-->
      </b-tab>
      <!-- end tab 6 -->

      <!-- start tab 7 -->
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("hr_employees.employee_profile.deduction") }}
          </span>
        </template>
        <monthly-deduction-details-page />
        <!--end-->
      </b-tab>
      <!-- end tab 7 -->

      <!-- start tab 8 -->
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("hr_employees.employee_profile.awards") }}
          </span>
        </template>
        <hr-employee-awards />
        <!--end-->
      </b-tab>
      <!-- end tab 8 -->
    </b-tabs>

    <!-- end tabs block -->
  </Layout>
</template>
