<script>
// import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
// import PageHeader from "@/components/page-header";
// import store from "@/state/store";
// var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
  },
  data() {
    return {
      HrAwards: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchHrAwards: null,
      HrAwardsSearchMode: false,
      searchModel: "",
      HrEmployeeAwards: [],
      // state: state,
    };
  },
  methods: {
    addHrAwards() {
      // console.log("**********####", this.state);
      var data = {
        title: "popups.addHrAwardTypes",
        inputs: [
          {
            model: "hr_award_type_id",
            type: "select",
            options: (() => {
              return this.HrAwards.map((data) => {
                return {
                  value: data.id,
                  label: data.name,
                };
              });
            })(),
            label: this.$t("hr_awards.award_type"),
          },

          {
            model: "descr",
            type: "text",
            label: this.$t("hr_awards.descr"),
          },

          {
            model: "total_amount",
            type: "number",
            label: this.$t("hr_awards.total_amount"),
          },
          {
            model: "month",
            type: "month",
            label: this.$t("hr_awards.month"),
          },
          // {
          //   model: "year",
          //   type: "number",
          //   label: this.$t("hr_awards.year"),
          // },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.hr_employee_id = this.$route.params.id;
              obj.year = obj.month.split("-")[0];
              obj.month = obj.month.split("-")[1];
              this.http.post("hr-awards", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editHrAwards(app) {
      var data = {
        title: "popups.editHrAwardTypes",
        inputs: [
          {
            model: "hr_award_type_id",
            type: "select",
            options: (() => {
              return this.HrAwards.map((data) => {
                return {
                  value: data.id,
                  label: data.name,
                };
              });
            })(),
            label: this.$t("hr_awards.award_type"),
          },

          {
            model: "descr",
            type: "text",
            value: app.descr,

            label: this.$t("hr_awards.descr"),
          },

          {
            model: "total_amount",
            type: "number",
            value: app.total_amount,

            label: this.$t("hr_awards.total_amount"),
          },

          {
            model: "month",
            type: "number",
            value: app.month,
            label: this.$t("hr_awards.month"),
          },
          // {
          //   model: "month",
          //   type: "month",
          //   value: app.month,
          //   label: this.$t("hr_awards.month"),
          // },

          {
            model: "year",
            type: "number",
            value: app.year,
            label: this.$t("hr_awards.year"),
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.hr_employee_id = this.$route.params.id;
              this.http.put("hr-awards/update", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },

    search() {
      this.HrAwardsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("hr-awards/by-emp-id/search", {
          search: this.searchModel,
          limit: this.limit,
          hr_employee_id: this.$route.params.id,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.HrEmployeeAwards = res.data;
          console.log("######## files", this.HrAwards);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.HrAwardsSearchMode = false;
      this.get(this.page);
    },

    getHrAwardTypes() {
      this.http.get("hr-award-types").then((res) => {
        this.HrAwards = res.data;
      });
    },
    deleteHrAwards(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("hr-awards", app.id).then(() => {

            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("hr-awards/by-emp-id/paginate", {
          limit: this.limit,
          page: page,
          hr_employee_id: this.$route.params.id,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.HrEmployeeAwards = res.data;
        });
    },
  },
  created() {
    this.get(1);
    this.getHrAwardTypes();
  },
};
</script>

<template>
  <!-- <Layout> -->
  <div class="row">
    <div class="col-6">
      <div class="search-box chat-search-box w-50">
        <div class="position-relative">
          <input
            @keyup="search()"
            type="text"
            class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            :placeholder="$t('popups.search')"
            v-model="searchModel"
            style="background-color: #2a3042 !important"
          />
          <i class="bx bx-search-alt search-icon text-light"></i>
        </div>
      </div>
    </div>
    <div class="col-6" style="display: flex; justify-content: flex-end">
      <button
        class="btn-close text-light"
        v-if="HrAwardsSearchMode"
        @click="cancelappsearchMode()"
      ></button>
      <button
        type="button"
        @click="addHrAwards()"
        class="btn btn-light float-end mb-4"
      >
        <span
          class="bx bxs-plus-circle float-end fa-2x"
          style="color: #2a3042 !important"
        ></span>
      </button>
    </div>
  </div>

  <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
    <thead>
      <tr
        class="text-light text-center"
        style="background-color: #2a3042 !important"
      >
        <th scope="col">{{ $t("hr_awards.id") }}</th>
        <th scope="col">{{ $t("hr_awards.employee") }}</th>
        <th scope="col">{{ $t("hr_awards.award_type") }}</th>
        <th scope="col">{{ $t("hr_awards.descr") }}</th>
        <th scope="col">{{ $t("hr_awards.total_amount") }}</th>
        <th scope="col">{{ $t("hr_awards.paid_amount") }}</th>
        <th scope="col">{{ $t("hr_awards.month") }}</th>
        <th scope="col">{{ $t("hr_awards.year") }}</th>
        <th scope="col">{{ $t("hr_awards.date") }}</th>
        <th scope="col">{{ $t("hr_awards.operations") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(app, index) in HrEmployeeAwards"
        :key="app"
        class="text-center"
      >
        <td>{{ (index += 1) }}</td>
        <td>{{ app?.hr_employee?.name }}</td>
        <td>{{ app?.hr_award_type?.name }}</td>
        <td>{{ app?.descr }}</td>
        <td>{{ app?.total_amount }}</td>
        <td>{{ app?.paid_amount }}</td>
        <td>{{ app?.month }}</td>
        <td>{{ app?.year }}</td>
        <td>{{ app?.created?.split("T")[0] }}</td>

        <td class="d-flex">
          <a
            class="btn btn-primary mx-1"
            @click="editHrAwards(app)"
            href="javascript: void(0);"
            role="button"
            >{{ $t("popups.edit") }}</a
          >
          <a
            class="btn btn-danger"
            href="javascript: void(0);"
            role="button"
            @click="deleteHrAwards(app)"
            >{{ $t("popups.delete") }}</a
          >
        </td>
      </tr>
    </tbody>
  </table>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <div v-if="tot_pages > 1">
    <!--   Apps  paginate     -->
    <ul class="paginate paginate-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->
  <!-- </Layout> -->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
